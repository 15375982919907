import ImageComponent from '@/components/UI/image';
import { useTranslation } from 'react-i18next';
import { importImage } from '@/helper/functions';

import style from './onlineMenu.module.scss';

const OnlineMenu = () => {
  const { t, i18n } = useTranslation();

  return (
    <section className={style.menu}>
      <h2 className={style.menu_title}>{t('onlineMenu.title')}</h2>
      <p className={style.menu_text}>{t('onlineMenu.describe')}</p>
      <ImageComponent
        image={importImage('menu', i18n.language)}
        alt='menu'
        otherStyle={style.menu_image}
      />
    </section>
  );
};

export default OnlineMenu;
